'use client';

import { captureException } from '@sentry/nextjs';
import { FC, useEffect } from 'react';

import { BADGE } from 'src/general/components/BadgeIllustration/BadgeIllustration.type';
import { WholePageMessage } from 'src/general/sections/WholePageMessage/WholePageMessage';
import { ErrorPageProps } from 'src/types/appRouter.types';

const reloadPage = () => {
  if (typeof window !== 'undefined') {
    window.location.reload();
  }
};

const GeneralError: FC<ErrorPageProps> = ({ error }) => {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
    captureException(error);
  }, [error]);

  return (
    <WholePageMessage
      title="Oops, looks like something went wrong"
      iconSrc={BADGE.CARMA_TOUCH}
      buttonLabel="Try again"
      buttonOnClick={reloadPage}
      fullHeight
    />
  );
};

export default GeneralError;
